import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import './buTable.scss';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

class buTable extends React.Component {
  // Generates dataset row information
  generateRow = (buID, buName, buDesc) => {
    const { openDialog, status } = this.props;
    let actionButton;
    if (status === 'modify') {
      actionButton = (
        <Button onClick={openDialog(true, '', '', 'modifyBu')} className="modify-button">
          <EditIcon />
        </Button>
      );
    } else {
      actionButton = (
        <Button onClick={openDialog(true, status, buID, buName)} className="modify-button">
          <DeleteIcon />
        </Button>
      );
    }
    return (
      <TableRow key={`${buID}`} className="user-row">
        <TableCell>{buName}</TableCell>
        <TableCell>{buDesc}</TableCell>
        <TableCell align="center" className="history-cell">
          {actionButton}
        </TableCell>
      </TableRow>
    );
  }

  // Generates table body (rows) from business unit groups
  generateBody = (bus) => {
    const body = [];

    if (bus) {
      for (let i = 0; i < bus.length; i += 1) {
        const { businessUnitGroupID, businessUnitName, description } = bus[i];

        body.push(this.generateRow(businessUnitGroupID, businessUnitName, description));
      }
    }

    let tableRows = '';
    if (body.length !== 0) {
      tableRows = (
        <TableBody key="table-body">
          {body}
        </TableBody>
      );
    }

    return tableRows;
  }

  render() {
    const { auth, bus, status } = this.props;
    const { isAuthenticated } = auth;

    if (isAuthenticated) {
      if (bus.length) {
        const buTableBody = this.generateBody(bus);
        return (
          <TableContainer component={Paper} className="user-table">
            <Table>
              <TableHead className="header-row">
                <TableRow>
                  <TableCell className="header-row-20">Name</TableCell>
                  <TableCell className="header-row-40">Description</TableCell>
                  <TableCell className="header-row-20" align="center">{status === 'delete' ? 'Remove' : 'Modify'}</TableCell>
                </TableRow>
              </TableHead>
              {buTableBody}
            </Table>
          </TableContainer>
        );
      }
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" className="upload-text">There are no business unit groups assigned to this dataset.</Typography>
          </Grid>
        </Grid>
      ); 
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className="admin-header">Admin</Typography>
          <div className="cp-div">
            <CircularProgress className="circular-progress" />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

buTable.propTypes = {
  auth: PropTypes.object.isRequired,
  bus: PropTypes.array.isRequired,
  openDialog: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(buTable));
