import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import App from './app/App';
import { history, store } from './configureStore';
import { isAuthenticated } from './auth/actions/authActions';

// Overriding some of the default material UI styling components
const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
      backgroundcolor: 'transparent'
    },
  },
  typography: {
    fontFamily: [
      'Mark Pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: '#400095'
        }
      }
    },
    MuiAutocomplete: {
      option: {
        '&[aria-selected="true"]': {
          backgroundColor: 'transparent'
        }
      }
    }
  }
});

// Kick off authenication when app starts
store.dispatch(isAuthenticated());

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
