import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DataTable from './DataTable/dataTable';
import './browseData.scss';

function browseData() {
  return (
    <Grid container>
      <Grid item xs={12} className="browse">
        <Typography variant="h4" className="browseData-header">Browse Data</Typography>
        <DataTable />
      </Grid>
    </Grid>
  );
}

export default browseData;
