class Constants {
    static env = process.env.REACT_APP_ENV;

    static awsRegion = process.env.REACT_APP_REGION;

    static apiGatewayId = process.env.REACT_APP_API_GATEWAY_ID;

    static apiGatewayUrl = `https://${Constants.apiGatewayId}.execute-api.${Constants.awsRegion}.amazonaws.com/${Constants.env}`;

    static fileExtSupported = ['xls', 'xlsx', 'csv', 'tsv'];

    static fileExt = this.fileExtSupported.map(i => '.' + i);

    static conversionFileExt = ['xls', 'xlsx'];

    static urlStatus = 'url_generated';
    
    static cancelStatus = 'canceled';

    static uploadFailedStatus = 'upload_failed';
    
    static uploadedStatus = 'uploaded';

    static uploadingStatus = 'uploading';

    static canceledStatus = 'canceled';

    static beginProcessStatus = 'begin_processing';

    static convertedStatus = 'converted';

    static validatedStatus = 'validated';

    static validationFailedStatus = 'validation_failed';

    static conversionFailedStatus = 'conversion_failed';

    static processingStatus = 'processing';

    static processedStatus = 'processed';

    static loadFailedStatus = 'load_failed';

    static successStatus = 'success';

    static processedStatuses = [
      this.validationFailedStatus, 
      this.validatedStatus, 
      this.cancelStatus,
      this.successStatus,
      this.conversionFailedStatus
    ];

    static conversionStatuses = [
      this.cancelStatus,
      this.successStatus,
      this.conversionFailedStatus,
      this.convertedStatus
    ]

    static noProcessingStatuses = [
      this.cancelStatus,
      this.successStatus,
      this.beginProcessStatus
    ]

    static scope = 'openid%20ResearchTransformAdminMFA%20profile';
}

export default Constants;