import * as actionTypes from './actionTypes';
import DatasetService from '../../services/datasetService';

export function getDatasetsStart() {
  return {
    type: actionTypes.GET_DATASETS_START
  };
}

export function getDatasetsSuccess(datasets) {
  return {
    type: actionTypes.GET_DATASETS_SUCCESS,
    datasets
  };
}

export function getDatasets() {
  return function getDatasetsDispatch(dispatch) {
    dispatch(getDatasetsStart());
    return DatasetService.getDataSetInfo().then((datasets) => {
      dispatch(getDatasetsSuccess(datasets));
    }).catch((err) => {
      throw (err);
    });
  };
}
