import * as actionTypes from './actionTypes';
import AuthService from '../../services/authService';
import DatasetService from '../../services/datasetService';

export function notAuthenticated() {
  return {
    type: actionTypes.NOT_AUTHENTICATED
  };
}

export function authenticated(userInfo) {
  return {
    type: actionTypes.AUTHENTICATED,
    userInfo
  };
}

export function authenticationStart() {
  return {
    type: actionTypes.AUTHENTICATION_START
  };
}

export function isAuthenticated() {
  return function isAuthenticatedDispatch(dispatch) {
    dispatch(authenticationStart());
    return AuthService.isAuthenticated().then((data) => {
      if (data == null) {
        dispatch(notAuthenticated());
      } else {
        dispatch(authenticated(data));
      }
    });
  };
}

export function getUserBusStart() {
  return {
    type: actionTypes.GET_USER_BUS_START
  };
}

export function getUserBusSuccess(user) {
  return {
    type: actionTypes.GET_USER_BUS_SUCCESS,
    user
  };
}

export function getUserBus() {
  return function getUserBusDispatch(dispatch) {
    dispatch(getUserBusStart());
    return DatasetService.getUserInfo().then((user) => {
      dispatch(getUserBusSuccess(user));
    }).catch((err) => {
      throw (err);
    });
  };
}
