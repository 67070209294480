import {
  Switch, Route, Redirect
} from 'react-router-dom';
import React from 'react';
import Browse from '../browse/browseData';
import Export from '../export/exportData';
import Upload from '../upload/uploadData';
import UserAdmin from '../admin/userGroup';
import PlatformAdmin from '../admin/platform';
import Utils from '../services/utils';

function Routes(params) {
  return (
    <Switch>
      <Route path="/browse" component={Browse} />
      <Route path="/export" component={Export} />
      <Route path="/upload" component={Upload} />
      {
        Utils.isAdmin(params.role) ? <Route path="/userAdmin" component={UserAdmin} /> : null
      }
      {
        params.role === 'platformAdmin' ? <Route path="/platformAdmin" component={PlatformAdmin} /> : null
      }
      <Redirect to="/browse" />
    </Switch>
  );
}

export default Routes;
