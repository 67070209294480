import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Collapse from '@material-ui/core/Collapse';
import './dataTable.scss';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShowMoreText from 'react-show-more-text';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getDatasets } from '../actions/datasetActions';
import Certify from '../../assets/Certificate Green.png';
import TableIcon from '../../assets/Table.png';
import Database from '../../assets/Database Unfilled Blue.png';

class DataTable extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {
      isExpanded: {},
    };
  }

    // Adds the row to the expanded state if the button is clicked
    expandyOnClick = (rowIndex) => () => {
      const { isExpanded } = this.state;
      const newIsExpanded = { ...isExpanded };

      if (newIsExpanded[rowIndex]) {
        newIsExpanded[rowIndex] = false;
        this.setState({ isExpanded: newIsExpanded });
      } else {
        newIsExpanded[rowIndex] = true;
        this.setState({ isExpanded: newIsExpanded });
      }
    };

    // Puts the dataset name and table name on the URL path
    exportDataset = (datasetName, tableName) => () => {
      const { history } = this.props;
      history.push({ pathname: '/export', search: `?dataset=${datasetName}&table=${tableName}` });
    };

    // Generates dataset row information
    generateRow(rowNum, datasetName, desc, refresh, volume) {
      const { isExpanded } = this.state;
      const row = (
        <TableRow key={`${datasetName}-${rowNum}`} className="dataset-row">
          <TableCell className="dataset-row-8">
            <Button onClick={this.expandyOnClick(rowNum)} disableTouchRipple style={{ backgroundColor: 'transparent' }}>{isExpanded[rowNum] ? <ExpandLess /> : <ExpandMore />}</Button>
          </TableCell>
          <TableCell className="dataset-row-6">
            <Tooltip disableFocusListener disableTouchListener title="Dataset"><img src={Database} alt="Dataset" width="30" /></Tooltip>
          </TableCell>
          <TableCell component="th" scope="row" className="dataset-row-21-name">
            {datasetName}
          </TableCell>
          <TableCell className="dataset-row-8" />
          <TableCell className="dataset-row-21">
            <ShowMoreText
              /* Default options */
              lines={4}
              more="more"
              less="less"
              anchorClass="dataset-cell"
              onClick={this.executeOnClick}
              expanded={false}
            >
              {desc}
            </ShowMoreText>
          </TableCell>
          <TableCell align="center" className="dataset-row-11">{refresh}</TableCell>
          <TableCell align="right" className="dataset-row-11">{volume}</TableCell>
          <TableCell className="dataset-row-14"/>
        </TableRow>
      );

      return row;
    }

    // Creates table detailed rows
    generateExpandyRows(rowNum, datasetName, tablesObj) {
      const dataTableRows = [];
      const { isExpanded } = this.state;

      for (let i = 0; i < tablesObj.length; i += 1) {
        const colNum = i;
        const { tableName } = tablesObj[i];
        const desc = tablesObj[i].tableDescr;
        // Convert date according to user's timezone
        const refresh = moment.unix(tablesObj[i].tableLastUpdated).format('L');
        const volume = tablesObj[i].tableSize;
        const certified = tablesObj[i].tableCertified;

        dataTableRows.push(
          <TableRow key={`${datasetName}-${rowNum}-${colNum}`} className="expandy-table-row">
            <TableCell className="expandy-cell-8" />
            <TableCell className="expandy-cell-6">
              <Tooltip disableFocusListener disableTouchListener title="Table"><img src={TableIcon} alt="Table" width="30" /></Tooltip>
            </TableCell>
            <TableCell className="expandy-cell-name-21" component="th" scope="row">{tableName}</TableCell>
            <TableCell className="expandy-cell-8">
              {certified ? <img src={Certify} alt="Certified" className="dataset-row-s3" /> : null}
            </TableCell>
            <TableCell className="expandy-cell-21">
              <ShowMoreText
                /* Show more button */
                lines={4}
                more="more"
                less="less"
                anchorClass="expandy-cell-21"
                onClick={this.executeOnClick}
                expanded={false}
              >
                {desc}
              </ShowMoreText>
            </TableCell>
            <TableCell className="expandy-cell-11" align="center">{refresh}</TableCell>
            <TableCell className="expandy-cell-11" align="right">{volume}</TableCell>
            <TableCell className="expandy-cell-14" align="right">
              <Button onClick={this.exportDataset(datasetName, tableName)} variant="outlined" className="export-button" startIcon={<CloudDownloadIcon />}>Export</Button>
            </TableCell>
          </TableRow>
        );
      }

      const expandyRow = (
        // Row is hidden unless the row number is added to the expanded state
        <TableRow key={`expandy-row-${rowNum}`} className={`expandy-row ${rowNum % 2 === 1 ? '' : 'even-rows'} ${isExpanded[rowNum] ? '' : 'hidden-row'}`}>
          <TableCell className="expandy-row-cell" colSpan="8">
            <Collapse in={isExpanded[rowNum]}>
              <Table className="expandy-table">
                <TableBody key={`table-body${rowNum}`}>
                  {dataTableRows}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      );

      return expandyRow;
    }

    // Generates table body (rows) from datasets object, both expanded and non-expanded rows
    generateBody(datasets) {
      const body = [];
      const rowObj = datasets;

      if (rowObj) {
        for (let i = 0; i < rowObj.length; i += 1) {
          const rowNum = i;
          const { datasetName } = rowObj[i];
          const desc = rowObj[i].datasetDescr;
          // Convert date according to user's timezone
          const refresh = moment.unix(rowObj[i].datasetLastUpdated).format('L');
          const volume = rowObj[i].datasetSize;

          body.push(this.generateRow(rowNum, datasetName, desc, refresh, volume));

          const tablesObj = rowObj[i].exportTables;

          body.push(this.generateExpandyRows(rowNum, datasetName, tablesObj));
        }
      }

      return (
        <TableBody key="table-body" className="table-body">
          {body}
        </TableBody>
      );
    }

    render() {
      // Pulls datasets from API call that populates Redux
      const { dataset, auth } = this.props;
      const { didLoad, datasetObjs } = dataset;
      const { isAuthenticated } = auth;

      // Checks to see if datasets have loaded and user is authenticated before showing table data
      if (didLoad && isAuthenticated) {
        return (
          <TableContainer component={Paper} className="browse-table">
            <Table>
              <TableHead className="header-row">
                <TableRow>
                  <TableCell className="header-row-8" />
                  <TableCell className="header-row-6">Type</TableCell>
                  <TableCell className="header-row-21">Name</TableCell>
                  <TableCell className="header-row-8">Certified</TableCell>
                  <TableCell className="header-row-21">Description</TableCell>
                  <TableCell className="header-row-11" align="center">Last Refresh</TableCell>
                  <TableCell className="header-row-11" align="right">Volume</TableCell>
                  <TableCell className="header-row-14" />
                </TableRow>
              </TableHead>
              {this.generateBody(datasetObjs)}
            </Table>
          </TableContainer>
        );
      } 
      // If datasets have not loaded, return spiny
      return (
        <div className="browse-cp-div">
          <CircularProgress className="circular-progress" />
        </div>
      );
    }
}

const mapStateToProps = (state) => ({
  dataset: state.dataset,
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getDatasets,
  }, dispatch);
}

DataTable.propTypes = {
  auth: PropTypes.object.isRequired,
  dataset: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataTable));
