import React from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Toolbar from '@material-ui/core/Toolbar';

import './footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <Toolbar>
          <Grid container>
            <Grid item xs={6}>
              <Typography>
                Robust Analytics Platform To Optimize Research
              </Typography>
            </Grid>
            <Grid item xs={6} className="footer-text-contact">
              <Typography align="right" fontSize="14px" className="footer-text-contact">
                <a href="https://jira.inbcu.com/servicedesk/customer/portal/1421" target="_blank" rel="noreferrer noopener" className="link">Contact Us</a>               
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </div>
    </footer>
  );
}

export default Footer;
