import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import './userTable.scss';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Button from '@material-ui/core/Button';

class userTable extends React.Component {
  // Generates dataset row information
  generateRow = (rowNum, ssoID, role) => {
    const { openDialog } = this.props;
    let admin;

    if (role === 'admin') {
      admin = true;
    } else {
      admin = false;
    }

    return (
      <TableRow key={`${ssoID}-${rowNum}`} className="user-row">
        <TableCell>{ssoID}</TableCell>
        <TableCell align="center">
          {admin
            ? (
              <div className="success-icon-div">
                <CheckCircleOutline className="success-icon" />
              </div>
            )
            : null}
        </TableCell>
        <TableCell align="center" className="history-cell">
          <Button onClick={openDialog(true, ssoID, admin, 'modify')} className="modify-button">
            <EditIcon />
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  // Generates table body (rows) from user info
  generateBody = (userInfo) => {
    const body = [];

    if (userInfo) {
      for (let i = 0; i < userInfo.length; i += 1) {
        const rowNum = i;
        const { ssoID, role } = userInfo[i];

        body.push(this.generateRow(rowNum, ssoID, role));
      }
    }

    let tableRows = '';
    if (body.length !== 0) {
      tableRows = (
        <TableBody key="table-body">
          {body}
        </TableBody>
      );
    }

    return tableRows;
  }

  render() {
    const { auth, users } = this.props;
    const { isAuthenticated } = auth;
    const userTableBody = this.generateBody(users);

    if (isAuthenticated) {
      // Checks to see if there are users associated
      if (users.length) {
        return (
          <TableContainer component={Paper} className="user-table">
            <Table>
              <TableHead className="header-row">
                <TableRow>
                  <TableCell className="header-row-40">SSO ID</TableCell>
                  <TableCell className="header-row-20" align="center">Admin</TableCell>
                  <TableCell className="header-row-20" align="center">Modify</TableCell>
                </TableRow>
              </TableHead>
              {userTableBody}
            </Table>
          </TableContainer>
        );
      }
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" className="upload-text">There are no users assigned to this business unit group.</Typography>
          </Grid>
        </Grid>
      );     
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className="admin-header">Admin</Typography>
          <div className="cp-div">
            <CircularProgress className="circular-progress" />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

userTable.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  openDialog: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(userTable));
