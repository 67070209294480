import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import './filterTable.scss';

class FilterTable extends React.Component {
  constructor(props, context) {
    super(props);
  }

  splitColumns = (arr) => {
    const chunks = [];
    let chunkSize = Math.floor(arr.length / 3);
    let remainder = arr.length % 3;

    let begin = 0;
    let end = 0;
    // Changing chunk size when there are only a few columns
    if (remainder > chunkSize) {
      chunkSize = Math.floor(arr.length / 2);
      remainder = arr.length % 2;
    }
    for (let i = 0; i < 3; i += 1) {
      end += chunkSize;
      chunks.push(arr.slice(begin, end));
      begin += chunkSize;
    }
    if (remainder > 0) {
      chunks.push(arr.slice(end, end + remainder));
    }

    return chunks;
  }

  // Creates column checkboxes from list of column objects
  generateColumns(columns) {
    const {
      allSelected, onColumnChange, isExporting, selectedColumns 
    } = this.props;
    const columnChecks = [];
    const columnGrids = [];

    if (columns) {
      const selectAllCheck = (
        <FormControlLabel
          control={(
            <Checkbox
              key="checkbox-all"
              checked={allSelected}
              onChange={onColumnChange('ALL', columns)}
              disabled={isExporting}
              color="default"
              classes={{ root: 'checkbox' }}
              disableRipple
            />
          )}
          label="(Select All)"
          classes={{ root: 'form-control-label', label: 'form-control-label' }}
        />
      );
      columnChecks.push(selectAllCheck);
      for (let i = 0; i < columns.length; i += 1) {
        const { columnName } = columns[i];
        const isChecked = !!selectedColumns[columnName];
        const checkbox = (
          <FormControlLabel
            key={`column-${i}`}
            control={(
              <Checkbox
                key={`checkbox-${i}`}
                checked={isChecked}
                onChange={onColumnChange('COLUMN', '', columnName, columns)}
                disabled={isExporting}
                color="default"
                classes={{ root: 'checkbox' }}
                disableRipple
              />
            )}
            label={columnName}
            classes={{ root: 'form-control-label', label: 'form-control-label' }}
          />
        );
        columnChecks.push(checkbox);
      }

      const columnArrays = this.splitColumns(columnChecks, 4);

      for (let i = 0; i < columnArrays.length; i += 1) {
        let gridItem;
        if (i === 0) {
          gridItem = (
            <Grid item xs={3} className="columns" key={`column-grid-${i}`}>
              <FormControl component="fieldset" className="columns" key={`fieldset-${i}`}>
                <FormControl component="fieldset" className="columns" key={`fieldset-columns-${i}`}>
                  <FormLabel component="legend" style={{ color: '#757575' }} className="columns-label">Columns</FormLabel>
                  {columnArrays[i][0]}
                </FormControl>
                <FormGroup>
                  {columnArrays[i].slice(1, columnArrays[i].length)}
                </FormGroup>
              </FormControl>
            </Grid>
          );
        } else {
          gridItem = (
            <Grid item xs={3} className="columns" key={`column-grid-${i}`}>
              <FormControl component="fieldset" key={`fieldset-${i}`}>
                <FormGroup className="columns-top">
                  {columnArrays[i]}
                </FormGroup>
              </FormControl>
            </Grid>
          );
        }
        columnGrids.push(gridItem);
      }
    }

    return columnGrids;
  }

  // Generates dropdown, operator and textbox for filters
  generateFilters(columns) {
    const { selectedFilters, isExporting, onFilterChange } = this.props;
    const filters = [];
    const columnMenu = [];

    if (columns) {
      for (let i = 0; i < columns.length; i += 1) {
        const { columnName } = columns[i];
        const columnMenuItem = (
          <MenuItem key={columnName + i} value={columnName}>{columnName}</MenuItem>
        );
        columnMenu.push(columnMenuItem);
      }

      for (let i = 0; i < selectedFilters.length; i += 1) {
        const filterColumn = (
          <Grid item xs={3} className="criteria" key={`grid-filter-column-${i}`}>
            <Button disabled={isExporting} className="delete-button">
              <Tooltip title="Delete">
                <DeleteIcon onClick={onFilterChange('DELETE', i)} />
              </Tooltip>
            </Button>
            <FormControl key={`filter-column-${i}`} className="criteria">
              <InputLabel className="select-box">
                {!selectedFilters[i].column ? 'Column' : ''}
              </InputLabel>
              <Select
                key={`filter-column-${i}`}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedFilters[i].column}
                onChange={onFilterChange('column', i, columns)}
                style={{ minWidth: 168 }}
                disabled={isExporting}
                className="select-box"
              >
                {columnMenu}
              </Select>
            </FormControl>
          </Grid>
        );
        filters.push(filterColumn);

        // Setting operator list depending on column type (default is boolean values)
        let operatorList = ['=', '!='];
        const columnType = selectedFilters[i].type;
        let dateType = '';

        if (['BIGINT', 'NUMBER', 'DOUBLE', 'FLOAT', 'TINYINT', 'SMALLINT', 'INT', 'INTEGER'].includes(columnType)) {
          operatorList = ['=', '!=', '>', '<', '>=', '<='];
        } else if (['STRING', 'TEXT', 'VARCHAR', 'CHAR'].includes(columnType)) {
          operatorList = ['=', '!=', 'IN', 'NOT IN', 'CONTAINS', 'DOES NOT CONTAIN'];
        } else if (['TIMESTAMP', 'TIMESTAMP_LTZ', 'TIMESTAMP_NTZ', 'DATE'].includes(columnType)) {
          operatorList = ['=', '!=', '>', '<', '>=', '<=', 'IN', 'NOT IN', 'BETWEEN'];
          if (columnType === 'DATE') {
            dateType = 'date';
          } else {
            dateType = 'datetime-local';
          }
        }

        const operatorItems = [];
        if (selectedFilters[i].column) {
          for (let l = 0; l < operatorList.length; l += 1) {
            const operator = operatorList[l];
            const operatorMenuItem = (
              <MenuItem key={operator} value={operator}>{operator}</MenuItem>
            );
            operatorItems.push(operatorMenuItem);
          }
        }

        let filterDisable = true;
        let tooltip = 'Please select a column';
        if (selectedFilters[i].column) {
          tooltip = '';
          if (!isExporting) {
            filterDisable = false;
          }
        }
        const filterOperator = (
          <Grid item xs={3} className="operator" key={`grid-filter-op-${i}`}>
            <FormControl key={`filter-op-${i}`} className="select-criteria">
              <InputLabel className="select-box-input">
                {!selectedFilters[i].operator ? 'Operator' : null}
              </InputLabel>
              <Tooltip disableFocusListener disableTouchListener title={tooltip}>
                <Select
                  key={`filter-operator-${i}`}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFilters[i].operator}
                  onChange={onFilterChange('operator', i)}
                  style={{ minWidth: 168 }}
                  disabled={filterDisable}
                  className="select-box"
                >
                  {operatorItems}
                </Select>
              </Tooltip>
            </FormControl>
          </Grid>
        );
        filters.push(filterOperator);

        if (selectedFilters[i].operator === 'BETWEEN') {
          const filterCriteriaStart = (
            <Grid item xs={3} className="criteria">
              <Tooltip disableFocusListener disableTouchListener title={tooltip}>
                <TextField
                  key={`filter-start-criteria-${i}`}
                  id="outlined-basic"
                  onChange={onFilterChange('startCriteria', i)}
                  value={selectedFilters[i].startCriteria}
                  disabled={filterDisable}
                  type={dateType}
                  InputProps={{
                    className: 'text-box'
                  }}
                />
              </Tooltip>
            </Grid>
          );
          filters.push(filterCriteriaStart);
          const filterCriteriaEnd = (
            <Grid item xs={3} className="criteria">
              <Tooltip disableFocusListener disableTouchListener title={tooltip}>
                <TextField
                  key={`filter-end-criteria-${i}`}
                  id="outlined-basic"
                  onChange={onFilterChange('endCriteria', i)}
                  value={selectedFilters[i].endCriteria}
                  disabled={filterDisable}
                  type={dateType}
                  InputProps={{
                    className: 'text-box'
                  }}
                />
              </Tooltip>
            </Grid>
          );
          filters.push(filterCriteriaEnd);
        } else {
          let placeholder = '';
          let helperText = '';
          if (!selectedFilters[i].criteria && selectedFilters[i].column) {
            if (selectedFilters[i].sampleValue) {
              placeholder = `E.g. ${selectedFilters[i].sampleValue}`;
            }     
          }
          if (selectedFilters[i].validation) {
            helperText = 'Please enter a correct numeric value.';
          }

          const filterCriteria = (
            <Grid item xs={6} className="criteria" key={`grid-filter-criteria-${i}`}>
              <Tooltip disableFocusListener disableTouchListener title={tooltip}>
                <TextField
                  error={selectedFilters[i].validation}
                  key={`filter-criteria-${i}`}
                  id="outlined-basic"
                  placeholder={placeholder}
                  onChange={onFilterChange('criteria', i)}
                  value={selectedFilters[i].criteria}
                  disabled={filterDisable}
                  type={dateType}
                  helperText={helperText}
                  InputProps={{
                    className: 'text-box'
                  }}
                />
              </Tooltip>
            </Grid>
          );
          filters.push(filterCriteria);
        }
      }
    }
    return filters;
  }

  render() {
    const {
      columns, onFilterChange, isExporting, selectedFilters
    } = this.props;

    return (
      <Grid container>
        {this.generateColumns(columns)}
        <Grid item xs={12} className="filter-button-item">
          <FormLabel component="legend" className="filter-title">Filters</FormLabel>
          <Grid container className="criteria">
            {selectedFilters ? this.generateFilters(columns) : null}
            <div className="filter-title">
              <Tooltip title="Add Filter">
                <Button
                  variant="outlined"
                  className="export-button"
                  onClick={onFilterChange('NEW')}
                  disabled={isExporting}
                >
                  <Add />
                </Button>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

FilterTable.propTypes = {
  columns: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  onColumnChange: PropTypes.func.isRequired,
  selectedColumns: PropTypes.object.isRequired,
  allSelected: PropTypes.bool.isRequired,
  isExporting: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  browse: state.browse
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterTable));
