import React from 'react';
import './App.scss';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Routes from './routes';
import Footer from './footer';
import Header from './header';
import { getDatasets as actionGetDatasets } from '../browse/actions/datasetActions';
import Utils from '../services/utils';

class App extends React.Component {
  constructor(props, context) {
    super(props);

    const { dataset, auth, getDatasets } = this.props;
    const { didLoad, isLoading } = dataset;
    const { isAuthenticated } = auth;
    if (!didLoad && !isLoading && isAuthenticated) {
      getDatasets();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dataset, auth, getDatasets } = this.props;
    const { didLoad, isLoading } = dataset;
    const { isAuthenticated } = auth;

    if (!didLoad && !isLoading && isAuthenticated) {
      getDatasets();
    }
  }

  render() {
    let body;
    const { auth } = this.props;
    const { loggingIn, isAuthenticated, userInfo } = auth;
    const { platformAdmin, isAdminOf } = userInfo;

    let containerClass = 'app-container';
    if (loggingIn) {
      body = (
        <div className="cp-div">
          <CircularProgress className="circular-progress" />
        </div>
      );
    } else if (!isAuthenticated) {
      body = (
        <div className="authenticate-message">
          Unable to authenticate user
        </div>
      );
      containerClass = 'app-container-center';
    } else {
      body = (
        <Routes role={Utils.getRole(platformAdmin, isAdminOf)} />
      );
    }

    return (
      <div className="app">
        <div className="container">
          <Header />
          <main className={containerClass}>
            {body}
          </main>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    dataset: state.dataset
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getDatasets: actionGetDatasets
  }, dispatch);
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  dataset: PropTypes.object.isRequired,
  getDatasets: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
