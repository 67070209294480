import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import './exportData.scss';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Clear from '@material-ui/icons/Clear';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';
import FilterTable from './FilterTable/filterTable';
import DatasetService from '../services/datasetService';

class exportData extends React.Component {
  constructor(props, context) {
    super(props);

    const { location, dataset } = this.props;
    const { didLoad, datasetObjs } = dataset;
    const { search } = location;
    const params = queryString.parse(search);
    const datasetSelected = params.dataset ? params.dataset : '';
    const tableSelected = params.table ? params.table : '';
    let fullExport = false;

    // Check to see if navigation from the browse page which doesn't trigger componenDidUpdate
    if (didLoad && tableSelected) {
      const { exportTables } = datasetObjs.find((x) => x.datasetName === datasetSelected);
      const { tableRawSize } = exportTables.find((x) => x.tableName === tableSelected);

      // Check to see if table size is under 10 MB
      if (tableRawSize < 10485760) {
        fullExport = true;
      }
    }

    this.state = {
      datasetSelected,
      tableSelected,
      dialogOpen: false,
      isExporting: false,
      filters: [],
      columnsSelected: {},
      allSelected: false,
      fullExport
    };
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Pulls the history if there is a table selected but no history objects
    const { datasetSelected, tableSelected } = this.state;
    const { dataset } = this.props;
    const { didLoad } = dataset;
     
    if ((prevProps.dataset.didLoad === false && didLoad && tableSelected) 
    || (didLoad && tableSelected && tableSelected !== prevState.tableSelected)) {
      const { datasetObjs } = dataset;

      const { exportTables } = datasetObjs.find((x) => x.datasetName === datasetSelected);
      const { tableRawSize } = exportTables.find((x) => x.tableName === tableSelected);

      // Check to see if table size is under 10 MB
      if (tableRawSize < 10485760) {
        this.setState({ fullExport: true });
      } else {
        this.setState({ fullExport: false });
      }
    }
  }
  
  // Clears the page of all selection and starts with a brand new state.
  // Also changes the history to remove query parameters if any.
  clearAll = () => {
    const nextState = {
      datasetSelected: '',
      tableSelected: '',
      dialogOpen: false,
      isExporting: false,
      filters: [],
      columnsSelected: {},
      allSelected: false,
      fullExport: false
    };
    this.setState(nextState);
    const { history } = this.props;
    history.push({ pathname: '/export' });
  };

  // Exports all selected columns and filters to run the query on the backend, then displays modal
  exportData = async () => {
    this.setState({ isExporting: true, dialogOpen: true });

    const columnsList = [];
    const {
      columnsSelected, datasetSelected, tableSelected, filters 
    } = this.state;
    const { dataset } = this.props;
    const { datasetObjs } = dataset;

    // Turns the columns selected object into a list for more efficient backend processing
    Object.keys(columnsSelected).forEach((key, index) => {
      if (columnsSelected[key] === true) {
        columnsList.push(key);
      }
    });

    const { exportTables } = datasetObjs.find((x) => x.datasetName === datasetSelected);
    let tableType = '';
    for (let i = 0; i < exportTables.length; i += 1) {
      if (exportTables[i].tableName === tableSelected) {
        // Need to include table type (S3 or Snowflake) in payload
        tableType = exportTables[i].tableType;
        break;
      }
    }

    const queryPayload = {
      dataset: datasetSelected,
      tableType,
      table: tableSelected,
      columns: columnsList,
      filters
    };

    DatasetService.postQuery(queryPayload).then((response) => {
      if (response.errorMessage !== '' && response.errorMessage !== undefined) {
        this.setState({ 
          dialogOpen: true, 
          errorMessage: response.errorMessage, 
          isExporting: false 
        });
      } else {
        this.setState({ dialogOpen: true, isExporting: false });
        window.open(response.presignedURL);
      }
    });
  };

  // Closes the dialog and resets the state
  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
    this.setState({ errorMessage: '', isExporting: false });
  };

  // Handles creating a new filter object, changing, or deleting.
  // Type passed in is the filter component being changed.
  handleFilterOnChange = (type, id, columns) => (event) => {
    const { filters } = this.state;
    const newFilters = [...filters];
    if (type === 'NEW') {
      const filterObj = {
        column: '',
        operator: '',
        type: '',
        sampleValue: ''
      };

      newFilters.push(filterObj);
      this.setState({ filters: newFilters });
    } else if (type === 'DELETE') {
      // Removes specific filter
      newFilters.splice(id, 1);
      this.setState({ filters: newFilters });
    } else {
      const filterObj = newFilters[id];
      filterObj[type] = event.target.value;
      // If columns are being changed, add the column type to be used in backend processing
      if (type === 'column') {
        // Clear values from the filter object when changing column name
        filterObj.operator = '';
        filterObj.criteria = '';
        delete filterObj.startCriteria;
        delete filterObj.endCriteria;
        for (let i = 0; i < columns.length; i += 1) {
          if (columns[i].columnName === event.target.value) {
            filterObj.type = columns[i].columnType;
            filterObj.sampleValue = columns[i].sampleValue;
            if (filterObj.operator) {
              if (filterObj.operator === 'BETWEEN') {
                delete filterObj.criteria;
                filterObj.startCriteria = '';
                filterObj.endCriteria = '';
              }
            }
          }
        }
      }
      if (type === 'criteria') {
        const columnType = filterObj.type;

        if (['BIGINT', 'NUMBER', 'DOUBLE', 'FLOAT', 'TINYINT', 'SMALLINT', 'INT', 'INTEGER'].indexOf(columnType) >= 0) {
          const criteria = event.target.value;

          // Tests to see if value input is a number. If not number, form validation is triggered.
          if (!/^-?(0|[1-9]\d*)(\.\d+)?$/.test(criteria) && criteria) {
            filterObj.validation = true;
          } else {
            filterObj.validation = false;
          }
        }
      }
      this.setState({ filters: newFilters });
    }
  };

  // Handles the column select boxes changing
  handleColumnOnChange = (type, columns, name, columnList) => (event) => {
    const { checked } = event.target;
    const nextState = {};
    const { columnsSelected } = this.state;
    const newColumnsSelected = { ...columnsSelected };
    if (type === 'ALL') {
      for (let i = 0; i < columns.length; i += 1) {
        const { columnName } = columns[i];
        newColumnsSelected[columnName] = checked;
      }
      nextState.columnsSelected = newColumnsSelected;
      nextState.allSelected = checked;
    } else {
      newColumnsSelected[name] = event.target.checked;
      nextState.columnsSelected = newColumnsSelected;

      let allSelected = true;
      for (let i = 0; i < columnList.length; i += 1) {
        const { columnName } = columnList[i];
        allSelected = allSelected && !!(newColumnsSelected[columnName]);
      }
      nextState.allSelected = allSelected;
    }

    this.setState(nextState);
  };

  // Determines whether there is at least one column and one filter selected. 
  // If there is, export is enabled.
  isExportDisabled = () => {
    const {
      columnsSelected, filters, isExporting, fullExport 
    } = this.state;

    const newColumnSelected = Object.keys(columnsSelected).some((k) => columnsSelected[k]);
    if (isExporting) {
      return true;
    }
    if (newColumnSelected) {
      if (!fullExport) {
        if (filters.length > 0) {
          let isFilterValid = true;
          for (let i = 0; i < filters.length; i++) {
            const {
              column, operator, criteria, startCriteria, endCriteria, validation 
            } = filters[i];

            if (operator === 'BETWEEN') {
              isFilterValid = isFilterValid && (column !== '' && operator !== '' && startCriteria !== '' && endCriteria !== ''
                            && typeof startCriteria !== 'undefined' && typeof endCriteria !== 'undefined');
            } else {
              isFilterValid = isFilterValid && (column !== '' && operator !== '' && criteria !== '' && typeof criteria !== 'undefined' && !validation);
            }
          }

          return !isFilterValid;
        }
        if (filters.length === 0) {
          return true;
        }
      } else {
        return false;
      }    
    }
    return true;
  };

  // Creates the clear and export buttons and dialog
  generateButtons = () => {
    const buttons = [];
    const {
      isExporting, dialogOpen, errorMessage, fullExport 
    } = this.state;

    const clearButton = (
      <Grid key="clear-button" item xs={2} className="button-item">
        <Button variant="outlined" className="export-button" disabled={isExporting} startIcon={<Clear />} onClick={this.clearAll}>Clear</Button>
      </Grid>
    );
    buttons.push(clearButton);

    // Set the tooltip based on if export is disabled and table size requires column and/or filter
    let exportToolTip = '';
    if (this.isExportDisabled()) {
      if (fullExport) {
        exportToolTip = 'Please select a column';
      } else {
        exportToolTip = 'Please select a column and filter';
      }
    }

    // If exporting, show circular progress on button and disable
    const exportButton = (
      <Grid key="export-button" item xs={2} className="button-item">
        {isExporting
          ? (
            <Button onClick={this.exportData} variant="outlined" className="export-button-loading" disabled={this.isExportDisabled()} startIcon={<CloudDownloadIcon />}>
              Export
            </Button>
          )
          : (
            <Tooltip disableFocusListener disableTouchListener title={exportToolTip}>
              <div>
                <Button onClick={this.exportData} variant="outlined" className="export-button" disabled={this.isExportDisabled()} startIcon={<CloudDownloadIcon />}>
                  Export
                </Button>
              </div>
            </Tooltip>
          )}
      </Grid>
    );
    buttons.push(exportButton);
    let dialogTitle;
    let dialogContent;
    let dialogActions = null;

    if (dialogOpen) {
      if (isExporting) {
        dialogTitle = 'Query in progress...';
        dialogContent = (
          <DialogContent className="dialog-content">
            <div className="dialog-div-query">
              <LinearProgress classes={{ colorPrimary: 'color', barColorPrimary: 'barcolor' }} />
              <DialogContentText id="message" />
            </div>
          </DialogContent>
        );
      } else if (errorMessage) {
        dialogTitle = 'An error occurred';
        dialogContent = (
          <DialogContent className="dialog-content">
            <div className="error-icon-div">
              <ErrorOutlineOutlinedIcon className="error-icon" />
            </div>
            <DialogContentText id="message">
              {errorMessage}
            </DialogContentText>
            <DialogContentText id="second-message">
              Please resolve any errors and click the export button to resubmit request.
            </DialogContentText>
          </DialogContent>
        );
      } else if (!errorMessage) {
        dialogTitle = 'Export Success';
        dialogContent = (
          <DialogContent className="dialog-content">
            <div className="success-icon-div">
              <CheckCircleOutline className="success-icon" />
            </div>
            <DialogContentText id="message">
              Please check your downloads folder
            </DialogContentText>
          </DialogContent>
        );
      }

      dialogActions = (
        <DialogActions>
          <Button onClick={this.handleDialogClose} className="dialog-button">
            OK
          </Button>
        </DialogActions>
      );
    }
    const dialog = (
      <Dialog
        key="dialog"
        open={dialogOpen}
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="query-dialog"
      >
        <DialogTitle id="alert-dialog-title" className="dialog-title">{dialogTitle}</DialogTitle>
        {dialogContent}
        {dialogActions}
      </Dialog>
    );

    buttons.push(dialog);

    return buttons;
  };
  
  // Update state with dataset selected if it changed
  handleDatasetChange = (event, newValue) => {
    const { datasetSelected } = this.state;
    if (newValue) {
      if (newValue !== datasetSelected) {
        this.setState({ datasetSelected: newValue, tableSelected: '', columnsSelected: {} });
      }
    }
  }

  // Update state with table selected if it changed 
  //  and add query parameters with dataset and table
  handleTableChange = (event, newValue) => {
    const { tableSelected, datasetSelected } = this.state;
    const { history } = this.props;

    if (newValue) {
      if (newValue !== tableSelected) {
        this.setState({ 
          tableSelected: newValue, filters: [], allSelected: false, columnsSelected: {}
        });
        history.push({ pathname: '/export', search: `?dataset=${datasetSelected}&table=${newValue}` });
      }
    }  
  }
  
  // Create an autocomplete select box for either datasets or tables depending on param passed
  generateAutocomplete = (type) => {
    const { datasetSelected, tableSelected } = this.state;
    const { dataset } = this.props;
    const { datasetObjs } = dataset;

    let options = []; 
    let optionsText;
    let value;
    let disabled = false;

    if (type === 'Dataset') {
      options = datasetObjs.map((a) => a.datasetName);
      optionsText = 'No Datasets Found';
      value = datasetSelected;
    } else {
      const datasetObj = datasetObjs.find((x) => x.datasetName === datasetSelected);
      if (datasetObj !== undefined) {
        const { exportTables } = datasetObj;
        if (exportTables) {
          options = exportTables.map((a) => a.tableName);
        }
      }
      optionsText = 'No Tables Found';
      value = tableSelected;
      disabled = this.disableTableSelect();
    }

    const autocomplete = (
      <Autocomplete
        id={"autocomplete-" + type}  
        value={value}
        options={options}
        onChange={type === 'Dataset' ? this.handleDatasetChange : this.handleTableChange} 
        noOptionsText={optionsText}
        selectOnFocus
        closeIcon=""
        getOptionLabel={(option) => option ? option : ''}
        getOptionSelected={(option, value) => {
          if (value === "") {
            return true;
          } else if (value === option) {
            return true;
          }
        }}
        classes={{ inputRoot: 'menu-selected', root: 'menu-selected' }}
        style={{ minWidth: 280 }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField 
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps
              }
            }} 
            label={type}
            InputLabelProps={{ classes: { root: 'menu-selected' } }}
            disabled={disabled}
          />
        )}
      />
    );

    return autocomplete;
  }

  // Creates a list of column objects based on a dataset and table
  generateColumnsList(datasetName, tableName) {
    const { dataset } = this.props;
    const { datasetObjs } = dataset;

    if (datasetObjs.length === 0) {
      return {};
    }
    const { exportTables } = datasetObjs.find((x) => x.datasetName === datasetName);

    if (exportTables.length === 0) {
      return {};
    }
    const columnObj = exportTables.find((x) => x.tableName === tableName);

    if (columnObj) {
      return columnObj.tableColumns;
    }
    return {};
  }

  // Disable table select box when dataset is not selected
  disableTableSelect() {
    const { datasetSelected, isExporting } = this.state;
    if (!datasetSelected && !isExporting) {
      return true;
    } return !!(datasetSelected && isExporting);
  }

  render() {
    const {
      datasetSelected, tableSelected, isExporting, filters, columnsSelected, allSelected 
    } = this.state;
    const { dataset, auth } = this.props;
    const { didLoad } = dataset;
    const { isAuthenticated } = auth;

    if (didLoad && isAuthenticated) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" className="exportData-header">Export Data</Typography>
          </Grid>
          <Grid item xs={12} className="select-boxes">
            <FormControl style={{ minWidth: 140, paddingRight: 70 }}>
              {this.generateAutocomplete('Dataset')}
            </FormControl>
            <FormControl style={{ minWidth: 140 }}>
              <Tooltip disableFocusListener disableTouchListener title={this.disableTableSelect() ? 'Please select a dataset' : ''}>
                {this.generateAutocomplete('Table')}            
              </Tooltip>
            </FormControl>
          </Grid>
          {tableSelected
            ? (
              <FilterTable
                columns={this.generateColumnsList(datasetSelected, tableSelected)}
                onFilterChange={this.handleFilterOnChange}
                selectedFilters={filters}
                onColumnChange={this.handleColumnOnChange}
                selectedColumns={columnsSelected}
                allSelected={allSelected}
                isExporting={isExporting}
              />
            ) : null}
          {tableSelected ? this.generateButtons() : null}
        </Grid>
      );
    } 
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className="exportData-header">Export Data</Typography>
          <div className="export-cp-div">
            <CircularProgress className="circular-progress" />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  dataset: state.dataset,
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

exportData.propTypes = {
  auth: PropTypes.object.isRequired,
  dataset: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(exportData));
