import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './historyTable.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import moment from 'moment';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Constants from '../../services/constants'; 

class HistoryTable extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {
      dialogOpen: false,
      errorMessage: '',
      rowsPerPage: 10,
      page: 0
    };
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false, errorMessage: '' });
  };

  handleDialogOpen = (errorMessage) => () => {
    this.setState({ dialogOpen: true, errorMessage });
  };

  TablePaginationActions = (props) => {
    const {
      count, page, rowsPerPage, onChangePage 
    } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className="footer-row">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  generateDialog() {
    const { errorMessage, dialogOpen } = this.state;
    const dialogTitle = (<DialogTitle id="alert-dialog-title" className="dialog-title">An Error Occurred</DialogTitle>);
    const dialogText = [];
    if (Array.isArray(errorMessage)) {
      for (let i = 0; i < errorMessage.length; i += 1) {
        dialogText.push(
          <DialogContentText id="message" key={`error-message-${i}`}>
            {errorMessage[i]}
          </DialogContentText>
        );
      }
    } else {
      dialogText.push(
        <DialogContentText id="message" key="error-message">
          {errorMessage}
        </DialogContentText>
      );
    }
    const dialogContent = (
      <DialogContent>
        <div className="error-icon-div">
          <ErrorIcon className="error-icon" />
        </div>
        {dialogText}
      </DialogContent>
    );
    const dialogActions = (
      <DialogActions>
        <Button onClick={this.handleDialogClose} className="dialog-button">
          OK
        </Button>
      </DialogActions>
    );
    const dialog = (
      <Dialog
        key="dialog"
        open={dialogOpen}
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog"
      >
        {dialogTitle}
        {dialogContent}
        {dialogActions}
      </Dialog>
    );
    return dialog;
  }

  generateStatus(status, rowNum, uploadHistory) {
    let statusIcon;
    if (status === Constants.urlStatus 
      || status === Constants.validatedStatus 
      || status === Constants.uploadedStatus 
      || status === Constants.beginProcessStatus 
      || status === Constants.convertedStatus) {
      statusIcon = (
        <Tooltip disableFocusListener disableTouchListener title="Processing">
          <div className="cloud-icon-div">     
            <CloudDoneIcon className="cloud-done-icon" />
            <CircularProgress size={45} className="cloud-circular-progress" />  
          </div>   
        </Tooltip>   
      );
    } else if (status === Constants.validationFailedStatus 
      || status === Constants.loadFailedStatus 
      || status === Constants.conversionFailedStatus) {
      const { errorMessage } = uploadHistory[rowNum];
      statusIcon = (
        <Button disableFocusRipple className="error-status">
          <Tooltip disableFocusListener disableTouchListener title="Error">
            <ErrorIcon className="error-icon" onClick={this.handleDialogOpen(errorMessage)} />
          </Tooltip>
        </Button>
      );
    } else if (status === Constants.successStatus) {
      statusIcon = (
        <Tooltip disableFocusListener disableTouchListener title="Complete">
          <CheckCircle className="success-icon" />
        </Tooltip>
      );
    }
    return statusIcon;
  }

  // Generates dataset row information
  generateRow(rowNum, fileName, size, uploadedBy, uploadDate, status, uploadHistory) {
    return (
      <TableRow key={`${fileName}-${rowNum}`} className="history-row">
        <TableCell className="history-cell-30">{fileName}</TableCell>
        <TableCell className="history-cell-15">{size}</TableCell>
        <TableCell className="history-cell-20">{uploadedBy}</TableCell>
        <TableCell className="history-cell-20">{uploadDate}</TableCell>
        <TableCell align="center" className="history-cell-status">{this.generateStatus(status, rowNum, uploadHistory)}</TableCell>
      </TableRow>
    );
  }

  // Generates table body (rows) from history object, both expanded and non-expanded rows
  generateBody(tableSelected, uploadHistory) {
    const body = [];
    let histObjs = uploadHistory;
    const { rowsPerPage, page } = this.state;

    if (histObjs) {
      if (rowsPerPage > 0) {
        histObjs = histObjs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }
      for (let i = 0; i < histObjs.length; i += 1) {
        const rowNum = i;
        const { fileName } = histObjs[i];
        const size = histObjs[i].fileSize;
        const uploadedBy = histObjs[i].userFullName;
        const { timestamp } = histObjs[i];
        const uploadDate = moment(timestamp).format('L');
        const uploadTime = moment(timestamp).format('LT');
        const uploadDateTime = `${uploadDate.toString()} ${uploadTime}`;
        const { status } = histObjs[i];

        body.push(this.generateRow(
          rowNum, 
          fileName, 
          size, 
          uploadedBy, 
          uploadDateTime, 
          status, 
          uploadHistory
        ));
      }
    }

    let tableRows = '';
    if (body.length !== 0) {
      tableRows = (
        <TableBody key="table-body">
          {body}
        </TableBody>
      );
    }

    return tableRows;
  }
  
  render() {
    // Gets upload history and table selected passed down from upload data
    const { uploadHistory, tableSelected, auth } = this.props;
    const { isAuthenticated } = auth;
    const { dialogOpen, rowsPerPage, page } = this.state;

    const tableBody = this.generateBody(tableSelected, uploadHistory);

    // Checks to see if user is authenticated and there is history show before displaying table data
    if (isAuthenticated && tableBody !== '') {
      return (
        <Grid container>
          <Grid item xs={12} key="grid-history-table">
            <TableContainer component={Paper}>
              <Table className="history-table">
                <TableHead className="header-row">
                  <TableRow>
                    <TableCell className="header-row-30">File Name</TableCell>
                    <TableCell className="header-row-15">Size</TableCell>
                    <TableCell className="header-row-20">Uploaded By</TableCell>
                    <TableCell className="header-row-20">Upload Date</TableCell>
                    <TableCell align="center" className="header-row-15">Status</TableCell>
                  </TableRow>
                </TableHead>
                {tableBody}
                {dialogOpen ? this.generateDialog() : null}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={uploadHistory.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={this.TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} key="grid-history-text">
            <Typography component="div">
              <Box fontStyle="italic" textAlign="right" m={1}>
                Showing last 30 days of history
              </Box>  
            </Typography>
          </Grid> 
        </Grid>
        
      );
    }
    return (
      <Typography variant="body1" className="upload-text">There are no recent uploads for this table.</Typography>
    );
  }
}

HistoryTable.propTypes = {
  tableSelected: PropTypes.string.isRequired,
  uploadHistory: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dataset: state.dataset,
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryTable));
