import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import './datasetTable.scss';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';

class datasetTable extends React.Component {
  // Generates dataset row information
  generateRow = (rowNum, datasetName, description) => (
    <TableRow key={`${datasetName}-${rowNum}`} className="user-row">
      <TableCell>{datasetName}</TableCell>
      <TableCell>{description}</TableCell>
    </TableRow>
  );

  // Generates table body (rows) from datasets
  generateBody = (datasets) => {
    const body = [];

    if (datasets) {
      for (let i = 0; i < datasets.length; i += 1) {
        const rowNum = i;
        const { datasetName, description } = datasets[i];

        body.push(this.generateRow(rowNum, datasetName, description));
      }
    }

    let tableRows = '';
    if (body.length !== 0) {
      tableRows = (
        <TableBody key="table-body">
          {body}
        </TableBody>
      );
    }

    return tableRows;
  }

  render() {
    const { auth, datasets } = this.props;
    const { isAuthenticated } = auth;
    const datasetTableBody = this.generateBody(datasets);

    if (isAuthenticated) {
      // Checks to see if there are datasets associated
      if (datasets.length) {
        return (
          <TableContainer component={Paper} className="dataset-bu-table">
            <Table>
              <TableHead className="header-row">
                <TableRow>
                  <TableCell className="header-row-40">Name</TableCell>
                  <TableCell className="header-row-60">Description</TableCell>
                </TableRow>
              </TableHead>
              {datasetTableBody}
            </Table>
          </TableContainer>
        );
      }
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" className="upload-text">There are no datasets assigned to this business unit group.</Typography>
          </Grid>
        </Grid>
      );      
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <div className="cp-div">
            <CircularProgress className="circular-progress" />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

datasetTable.propTypes = {
  auth: PropTypes.object.isRequired,
  datasets: PropTypes.array.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(datasetTable));
