import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isAuthenticated: false,
  loggingIn: false,
  userInfo: {},
  busLoaded: false
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.NOT_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false,
        loggingIn: false, 
      };
    case actionTypes.AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
        loggingIn: false,
        userInfo: action.userInfo, 
        busLoaded: true
      };
    case actionTypes.AUTHENTICATION_START:
      return {
        ...state,
        loggingIn: true,
        isAuthenticated: false 
      };
    case actionTypes.GET_USER_BUS_START:
      return {
        ...state,
        busLoaded: false
      };
    case actionTypes.GET_USER_BUS_SUCCESS:
      return {
        ...state,
        busLoaded: true,
        userInfo: action.user
      };
    default:
      return state;
  }
}
