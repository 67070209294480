import Constants from './constants';

class Utils {
  static isNumber(input) {
    const re = /^[0-9\b]+$/;
    // Tests to see if input is a number
    if (!re.test(input) && input) {
      return false;
    }
    return true;
  }

  // Delays request (time should be passed in milliseconds)
  static delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  // Generate the redirect URL by encoding the current URL
  static createRedirectUrl() {
    let encodedUri = window.location.href;
    encodedUri = encodeURIComponent(encodedUri);
    const appRedirect = process.env.REACT_APP_REDIRECT;
    const appClientID = process.env.REACT_APP_CLIENT_ID;
    const { scope } = Constants;
    return `${appRedirect}/fss/as/authorization.oauth2?client_id=${appClientID}&response_type=code&redirect_uri=${encodedUri}&scope=${scope}`;
  }

  static isNotNullorEmpty(value) {
    if (value !== null && value !== '' && value !== undefined) {
      return true;
    }
    return false;
  }

  static arrayContains(array, otherArray) {
    return otherArray.every((elem) => array.indexOf(elem) >= 0);
  }

  static getRole(platformAdmin, isAdminOf) {
    let role = 'user';

    if (platformAdmin) {
      role = 'platformAdmin';
    } else if (isAdminOf.length) {
      role = 'userAdmin';
    }
    return role;
  }

  static isAdmin(role) {
    if (role === 'platformAdmin' || role === 'userAdmin') {
      return true;
    }
    return false;
  }
}
  
export default Utils;