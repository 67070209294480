import axios from 'axios';

import Constants from './constants';
import Utils from './utils';

class DatasetService {
  constructor() {
    this.axiosRef = axios.create({
      baseURL: `${Constants.apiGatewayUrl}/`
    });
    const token = localStorage.getItem('accessToken');
    if (token) {
      this.setAuthToken(token);
    }
    this.cancelTokenSource = {};

    // Add a response interceptor
    this.axiosRef.interceptors.response.use(
      (response) => response,
      (error) => {
        // Do something with response error
        if (error.config && error.response && error.response.status === 401) {
          console.log('Need to re-authorize');
          // For now, we'll do this until we feel the need to add a refresh token
          window.location = Utils.createRedirectUrl();
        }
        return Promise.reject(error);
      }
    );
  }

  setAuthToken(token) {
    this.axiosRef.defaults.headers.common.Authorization = '';
    delete this.axiosRef.defaults.headers.common.Authorization;

    if (token) {
      this.axiosRef.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }

  // Gets kicked off once the user is authorized to store the dataset information in Redux
  getDataSetInfo() {
    return this.axiosRef.get('/datasets')
      .then((response) => Object.assign([], response.data))
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  getUserInfo() {
    return this.axiosRef.get('/userinfo')
      .then((response) => ({ ...response.data }))
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  // Sends query ID and gets query status in return
  postQueryStatus(payload) {
    return this.axiosRef.post('/querystatus', payload)
      .then((response) => ({ ...response.data }))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          return error.response.data;
        }
        throw error;
      });
  }

  // Loops through every 5 seconds to check query status.
  // Once query status either succeeds or fails, returns response.
  waitForQueryComplete(payload) {
    return this.postQueryStatus(payload)
      .then((response) => {
        const { queryStatus } = response;

        if (queryStatus === 'RUNNING' || queryStatus === 'QUEUED') {
          // Wait 5 seconds then make call for status again
          return Utils.delay(5000).then(() => this.waitForQueryComplete(payload));
        } 
        return response;
      })
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        return error;
      });
  }

  // Pieces together calls to run query and check status continuously.
  // Returns back error or success in same payload.
  postQuery(payload) {
    return this.axiosRef.post('/query', payload)
      .then((response) => {
        const body = response.data;
        const statusPayload = {
          queryId: body.queryId,
          query: body.query,
          tableType: payload.tableType,
          tableName: payload.table,
          fileName: body.fileName
        };
        return this.waitForQueryComplete(statusPayload)
          .then((queryCompleteResponse) => queryCompleteResponse);
      }).catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          if (error.response.status === 401) {
            return { errorMessage: 'UNAUTHORIZED. You are being directed away to re-authenticate' };
          }
          return error.response.data;
        } 
        throw error;
      });
  }

  // Creates presigned URL for upload
  postPresignedUrl(payload) {
    return this.axiosRef.post('/upload', payload)
      .then(((response) => response.data))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        throw error.response.data;
      });
  }

  // Cancels a file upload that is in progress using the Axios token source
  cancelUpload(fileId) {
    // Looks at cancel token to see if upload ID exists on object
    if (this.cancelTokenSource && this.cancelTokenSource[fileId]) {
      this.cancelTokenSource[fileId].cancel(`File upload: ${fileId}`);
      delete this.cancelTokenSource[fileId];
    }   
  }

  getUploadHistory(dataset, tableName) {
    return this.axiosRef.get(`/uploadhistory?dataset=${dataset}&tableName=${tableName}`)
      .then((response) => (Object.assign([], response.data)))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          if (error.response.status === 401) {
            return { errorMessage: 'UNAUTHORIZED. You are being directed away to re-authenticate' };
          }
          return error.response.data;
        }
        throw error;
      });
  }

  // Uploads a file by getting the presigned URL and putting to that URL.
  uploadFile(fileObj, presignedURL, fileId, progressFunction) {
    // Setting a cancel token allows cancelling the request if desired
    this.cancelTokenSource[fileId] = axios.CancelToken.source();

    return axios.put(presignedURL, fileObj, {
      headers: {
        'Content-Type': fileObj.type
      },
      onUploadProgress: progressFunction,
      cancelToken: this.cancelTokenSource[fileId].token
    }).then((response) => {
      delete this.cancelTokenSource[fileId];
      let newResponse = response.data;
      if (newResponse == null || newResponse === '') {
        newResponse = {
          fileId
        };
      }
      return newResponse;
    }).catch((error) => {
      if (axios.isCancel(error)) {
        return {
          cancelMessage: error.message
        };
      }

      console.log(`There was an error in the call to AWS. The error is: ${error.message}`);

      throw error;
    });
  }

  getUploadStatus(fileId) {
    return this.axiosRef.get(`/uploadstatus?uploadId=${fileId}`)
      .then((response) => ({ ...response.data }))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          return error.response.data;
        }
        throw error;
      });
  }
  
  getBuUsers(bu) {
    return this.axiosRef.get(`/admin/businessUnitGroups/${bu}/users`)
      .then((response) => (response.data))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          if (error.response.status === 401) {
            return { errorMessage: 'UNAUTHORIZED. You are being directed away to re-authenticate' };
          }
          return error.response.data;
        }
        throw error;
      });
  }

  getBuDatasets(bu) {
    return this.axiosRef.get(`/admin/businessUnitGroups/${bu}/datasets`)
      .then((response) => (response.data))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          if (error.response.status === 401) {
            return { errorMessage: 'UNAUTHORIZED. You are being directed away to re-authenticate' };
          }
          return error.response.data;
        }
        throw error;
      });
  }

  saveUserToBu(bu, ssoId, body) {
    return this.axiosRef.put(`/admin/businessUnitGroups/${bu}/users/${ssoId}`, body)
      .then((response) => (response.data)).catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          return error.response.data;
        }
        throw error;
      });
  }

  deleteUserFromBu(bu, ssoId, body) {
    return this.axiosRef.delete(`/admin/businessUnitGroups/${bu}/users/${ssoId}`, body)
      .then((response) => (response.data)).catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          return error.response.data;
        }
        throw error;
      });
  }

  getDatasetBus(dataset) {
    return this.axiosRef.get(`/admin/datasets/${dataset}/businessUnitGroups`)
      .then((response) => (response.data))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          if (error.response.status === 401) {
            return { errorMessage: 'UNAUTHORIZED. You are being directed away to re-authenticate' };
          }
          return error.response.data;
        }
        throw error;
      });
  }

  getBus() {
    return this.axiosRef.get('/admin/businessUnitGroups')
      .then((response) => (response.data))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          if (error.response.status === 401) {
            return { errorMessage: 'UNAUTHORIZED. You are being directed away to re-authenticate' };
          }
          return error.response.data;
        }
        throw error;
      });
  }

  putBu(buId, payload) {
    return this.axiosRef.put(`/admin/businessUnitGroups/${buId}`, payload)
      .then(((response) => response.data))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          if (error.response.status === 401) {
            return { errorMessage: 'UNAUTHORIZED. You are being directed away to re-authenticate' };
          }
          return error.response.data;
        }
        throw error;
      });
  }

  saveBuToDataset(dataset, buID) {
    return this.axiosRef.put(`/admin/datasets/${dataset}/businessUnitGroups/${buID}`)
      .then((response) => (response.data)).catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          return error.response.data;
        }
        throw error;
      });
  }

  deleteBuFromDataset(dataset, buID) {
    return this.axiosRef.delete(`/admin/datasets/${dataset}/businessUnitGroups/${buID}`)
      .then((response) => (response.data)).catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        if (error.response) {
          console.log(error.response.data);
          return error.response.data;
        }
        throw error;
      });
  }

  postBu(payload) {
    return this.axiosRef.post('/admin/businessUnitGroups', payload)
      .then(((response) => response.data))
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        throw error;
      });
  }
}

const datasetService = new DatasetService();

export default datasetService;
