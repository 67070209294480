import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth/reducers/authReducer';
import dataset from './browse/reducers/datasetReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  dataset
});