import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';

import './userGroup.scss';
import DatasetService from '../services/datasetService';
import Utils from '../services/utils';
import UserTable from './UserTable/userTable';
import DatasetTable from './DatasetTable/datasetTable';
import BuTable from './BuTable/buTable';
import { getUserBus as actionGetUserBus } from '../auth/actions/authActions';

class userGroup extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {
      buValue: '',
      dialogOpen: false,
      errorMessage: '',
      status: '',
      admin: false,
      user: '',
      userError: false,
      users: [],
      datasets: [],
      usersLoaded: false,
      datasetsLoaded: false,
      helperText: '',
      nameError: false,
    };
  }
  
    // Helps automatically refresh the page for the user when an action is taken
    refreshPage = () => {
      const { buValue } = this.state;
      const { getUserBus } = this.props;

      this.setState({ 
        usersLoaded: false, 
        datasetsLoaded: false, 
        dialogOpen: false 
      });
      DatasetService.getBuUsers(buValue.businessUnitGroupID).then((response) => {
        if (response.errorMessage) {
          this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
        } else {
          this.setState({
            users: response, usersLoaded: true, user: '', admin: false 
          });
        }
      });

      getUserBus();
      
      DatasetService.getBuDatasets(buValue.businessUnitGroupID).then((response) => {
        if (response.errorMessage) {
          this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
        } else {
          this.setState({ datasets: response, datasetsLoaded: true });
        }
      });
    }

    // Closes dialog
    handleDialogClose = () => {
      this.setState({
        dialogOpen: false, errorMessage: '',
      });
    };

    // Changes status to add and opens dialog
    addUser = () => {
      this.setState({ dialogOpen: true, status: 'add' });
    };

    // Open dialog and set specific values based on row 
    openDialog = (dialog, user, admin, status) => () => {
      this.setState({
        dialogOpen: dialog, user, admin, status
      });
    };

    // Handles selection of admin checkbox
    handleAdminOnChange = () => (event) => {
      const { checked } = event.target;
      this.setState({ admin: checked });
    };

    // Handles cancel button closing dialog and resetting values
    handleCancel = () => {
      this.setState({
        dialogOpen: false, admin: false, user: '', helperText: '' 
      });
    };

    // Saves user to business unit
    handleSaveUser = () => {
      const { user, admin, buValue } = this.state;
      if (user && user.length === 9) {
        const body = {};
        if (admin) {
          body.role = 'admin';
        } else {
          body.role = 'user';
        }
        DatasetService.saveUserToBu(buValue.businessUnitGroupID, user, body).then((response) => {
          if (response.errorMessage) {
            this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
          } else {
            this.refreshPage();
          }
        });
      } else {
        this.setState({ userError: true, helperText: 'Please enter a valid SSO ID' });
      }
    };

    // Saves user to business unit
    handleSaveBuInfo = () => {
      const { buValue } = this.state;
      if (buValue.businessUnitName !== '') {
        const payload = {
          businessUnitName: buValue.businessUnitName,
          description: buValue.description
        };
        
        DatasetService.putBu(buValue.businessUnitGroupID, payload).then((response) => {
          if (response.errorMessage) {
            this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
          } else {
            this.refreshPage();
          }
        });
      } else {
        this.setState({ nameError: true, helperText: 'Please enter a valid name' });
      }
    };

    // Sets the state to delete so delete confirmation modal will pop up
    handleDelete = () => {
      this.setState({ status: 'delete' });
    }

    // Deletes user from business unit group
    handleDeleteUser = () => {
      const { user, buValue } = this.state;
      // Checks to see if user is populated, otherwise shows error
      if (user) {
        DatasetService.deleteUserFromBu(buValue.businessUnitGroupID, user).then((response) => {
          if (response.errorMessage) {
            this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
          } else {
            this.refreshPage();
          }
        });
      } else {
        this.setState({ userError: true });
      }
    }

    // Handles change of user
    handleUserChange = () => (event) => {
      // Checks to see if SSO is a number
      const validation = !Utils.isNumber(event.target.value);
      let helperText = '';
      if (validation) {
        helperText = 'Please enter a numeric value';
      }
      this.setState({ user: event.target.value, userError: validation, helperText });
    };

    // Handles change of business unit object when creating a new business unit 
    //  (name, admin SSO, and description)
    buObjChange = (prop) => (event) => {
      const { buValue } = this.state;
      const newBuValue = { ...buValue };
      newBuValue[prop] = event.target.value;

      if (prop === 'businessUnitName') {
        // Resets the error and helper text when a name is inputted if there was an error
        this.setState({ buValue: newBuValue, nameError: false, helperText: '' });
      } else {
        this.setState({ buValue: newBuValue });
      }
    }

    handleModfiyBuModal = () => {
      this.setState({ dialogOpen: true, status: 'modify' });
    }

    // Generates bu info section with table
    generateBuSection = (buValue, datasetsLoaded, usersLoaded, busLoaded) => {
      if (busLoaded && datasetsLoaded && usersLoaded && buValue !== '') {   
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" className="admin-header">Business Unit Group Information</Typography>
            </Grid>
            <Grid item xs={10} />
            <BuTable
              bus={[buValue]}
              openDialog={this.openDialog}
              status="modify"
            />
          </Grid>
        );
      }
      
      // Returns spinny if not loaded
      return (
        <Grid container>
          <Grid item xs={12}>
            <div className="cp-div">
              <CircularProgress className="circular-progress" />
            </div>
          </Grid>
        </Grid>
      );
    }
    
    // Generates dataset section with table
    generateDatasetSection = (datasets, datasetsLoaded, usersLoaded) => {
      if (datasetsLoaded && usersLoaded && datasets !== undefined) {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" className="admin-header">Dataset</Typography>
            </Grid>
            <Grid item xs={10} />
            <DatasetTable
              datasets={datasets}
            />
          </Grid>
        );
      }
      return null;
    }

    // Update state with dataset selected if it changed
    handleBuChange = (event, newValue) => {
      const { auth } = this.props;
      const { userInfo } = auth;
      const bus = userInfo.isAdminOf;

      if (newValue) {
        const { buValue } = this.state;
        const newBu = newValue.businessUnitGroupID;

        if (newBu !== buValue.businessUnitGroupID) {
          const buObject = bus.filter((bu) => bu.businessUnitGroupID === newBu);
          let newBuObject;
          if (buObject.length > 0) {
            [newBuObject] = buObject;
          }
          this.setState({ buValue: newBuObject });

          DatasetService.getBuUsers(newBu).then((response) => {
            if (response.errorMessage) {
              this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
            } else {
              this.setState({ users: response, usersLoaded: true });
            }
          });

          DatasetService.getBuDatasets(newBu).then((response) => {
            if (response.errorMessage) {
              this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
            } else {
              this.setState({ datasets: response, datasetsLoaded: true });
            }
          });
        }
      }    
    };
  
  // Create an autocomplete select box for business unit groups
  generateAutocomplete = () => {
    const { buValue } = this.state;
    const { auth } = this.props;
    const { userInfo } = auth;
    const bus = userInfo.isAdminOf;

    const optionsText = 'No BUs Found';

    const autocomplete = (
      <Autocomplete
        id="autocomplete-bu"  
        value={buValue}
        options={bus}
        onChange={this.handleBuChange} 
        noOptionsText={optionsText}
        selectOnFocus
        closeIcon=""
        getOptionLabel={(option) => (option.businessUnitName ? option.businessUnitName : '')}
        getOptionSelected={(option, value) => {
          if (value === '') {
            return true;
          } if (value.businessUnitGroupID === option.businessUnitGroupID) {
            return true;
          }
          return false;
        }}
        classes={{ inputRoot: 'menu-selected', root: 'menu-selected' }}
        style={{ minWidth: 280 }}
        renderInput={(params) => (
          <TextField 
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps
              }
            }} 
            label="Business Unit Group"
            InputLabelProps={{ classes: { root: 'menu-selected' } }}
          />
        )}
      />
    );

    return autocomplete;
  }

  // Generates user section with table and add user button
  generateUserSection(users, usersLoaded, datasetsLoaded) {
    if (usersLoaded && datasetsLoaded && users !== undefined) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" className="admin-header">Users</Typography>
          </Grid>
          <Grid item xs={10} />
          <UserTable
            users={users}
            openDialog={this.openDialog}
          />
          <Grid key="upload-button" item xs={2} className="upload-button-div">
            <Button variant="outlined" className="upload-button" onClick={this.addUser}>
              Add User
            </Button>
          </Grid>
        </Grid>
      );
    }
    return null;
  }
    
  // Generates dialog based on status
  generateDialog() {
    let dialogContent;
    let dialogActions;
    let dialogTitle;
    const {
      status, errorMessage, dialogOpen, admin, userError, user, helperText, buValue, nameError
    } = this.state;

    if (errorMessage) {
      dialogTitle = <DialogTitle id="alert-dialog-title" className="user-dialog-title">An error occurred</DialogTitle>;
      dialogContent = (
        <DialogContent className="user-dialog">
          <div className="error-icon-div">
            <ErrorOutlineOutlinedIcon className="error-icon" />
          </div>
          <DialogContentText id="message">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
      );
      dialogActions = (
        <DialogActions>
          <Button onClick={this.handleDialogClose} className="user-dialog-button">
            OK
          </Button>
        </DialogActions>
      );
    } else {
      // Helps determine which class to use (needed for error formatting) for SSO ID text field
      let inputClass = 'sso-text-box';
      if (userError) {
        inputClass = 'sso-text-box-error';
      }

      // Default content for add and modify
      dialogContent = (
        <DialogContent classes={{ root: 'user-dialog-content' }}>
          <div className="sso-div">
            <TextField
              error={userError}
              required
              disabled={status === 'modify'}
              id="standard-error-helper-text"
              label="SSO ID"
              onChange={this.handleUserChange()}
              value={user}
              InputLabelProps={{ className: inputClass }}
              InputProps={{
                className: inputClass
              }}
              helperText={helperText}
            />
          </div>
          <div />
          <div className="admin-div">
            <FormControlLabel
              control={(
                <Checkbox
                  key="checkbox-all"
                  checked={admin}
                  onChange={this.handleAdminOnChange()}
                  color="default"
                  classes={{ root: 'checkbox' }}
                  disableRipple
                />
              )}
              label="Admin?"
              classes={{ root: 'form-control-label', label: 'form-control-label' }}
            />
          </div>
        </DialogContent>
      );
      if (status === 'add') {
        dialogTitle = <DialogTitle id="alert-dialog-title" className="user-dialog-title">Add User to Business Unit Group</DialogTitle>;
        dialogActions = (
          <DialogActions classes={{ root: 'user-dialog-button' }}>
            <Button onClick={this.handleCancel} className="user-dialog-button">
              Cancel
            </Button>
            <Button onClick={this.handleSaveUser} className="user-dialog-button">
              Add
            </Button>
          </DialogActions>
        );
      } else if (status === 'modify') {
        dialogTitle = <DialogTitle id="alert-dialog-title" className="user-dialog-title">Edit User in Business Unit Group</DialogTitle>;
        dialogActions = (
          <DialogActions classes={{ root: 'user-dialog-modify-button' }}>
            <div className="left-button-div">
              <Button onClick={this.handleDelete} className="user-delete-button">
                Delete
              </Button>
            </div>
            <span />
            <div className="right-button-div">
              <Button onClick={this.handleCancel} className="user-save-cancel-button">
                Cancel
              </Button>
              <Button onClick={this.handleSaveUser} className="user-save-cancel-button">
                Save
              </Button>
            </div>
          </DialogActions>
        );
      } else if (status === 'modifyBu') {
        let nameInputClass = 'text-box';
        if (nameError) {
          nameInputClass = 'name-text-box-error';
        }
        dialogTitle = <DialogTitle id="alert-dialog-title" className="user-dialog-title">Edit Business Unit Group Information</DialogTitle>;
        dialogActions = (
          <DialogActions classes={{ root: 'user-dialog-button' }}>
            <Button onClick={this.handleCancel} className="user-dialog-button">
              Cancel
            </Button>
            <Button onClick={this.handleSaveBuInfo} className="user-dialog-button">
              Save
            </Button>
          </DialogActions>
        );
        dialogContent = (
          <DialogContent classes={{ root: 'user-dialog-content' }}>
            <div className="bu-name">
              <TextField
                error={nameError}
                key="bu-name"
                required
                onChange={this.buObjChange('businessUnitName')}
                value={buValue.businessUnitName}
                label="Name"
                InputLabelProps={{ className: nameInputClass }}
                InputProps={{
                  className: nameInputClass
                }}
                helperText={helperText}
              />
            </div>
            <div className="bu-desc">
              <TextField
                key="bu-desc"
                onChange={this.buObjChange('description')}
                value={buValue.description}
                label="Description"
                className="bu-desc"
                InputProps={{
                  className: 'text-box'
                }}
              />
            </div>
          </DialogContent>
        );
      } else if (status === 'delete') {
        dialogTitle = (
          <DialogTitle id="alert-dialog-title" className="user-dialog-title">
            {`Delete "${user}" User from Business Unit Group?`}
          </DialogTitle>
        );
        dialogContent = '';
        dialogActions = (
          <DialogActions classes={{ root: 'user-dialog-button' }}>
            <Button onClick={this.handleCancel} className="user-dialog-button">
              Cancel
            </Button>
            <Button onClick={this.handleDeleteUser} className="user-dialog-delete-button">
              Delete
            </Button>
          </DialogActions>
        );
      }
    }

    const dialog = (
      <Dialog
        key="dialog"
        open={dialogOpen}
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ root: 'user-dialog', paper: 'user-dialog' }}
      >
        {dialogTitle}
        {dialogContent}
        {dialogActions}
      </Dialog>
    );

    return dialog;
  }
    
  render() {
    const { auth } = this.props;
    const {
      buValue, dialogOpen, users, datasets, datasetsLoaded, usersLoaded
    } = this.state;
    const { isAuthenticated, busLoaded } = auth;

    if (isAuthenticated) {
      return (
        <Grid container className="user-group">
          <Grid item xs={12} key="admin-header">
            <Typography variant="h4" className="admin-header">User Group Admin</Typography>
          </Grid>
          <Grid item xs={12} className="select-boxes-admin">
            <FormControl className="form-control">
              {this.generateAutocomplete()}
            </FormControl>
          </Grid>
          {buValue !== '' ? this.generateBuSection(buValue, usersLoaded, datasetsLoaded, busLoaded) : null}              
          {buValue !== '' ? this.generateUserSection(users, usersLoaded, datasetsLoaded) : null}
          {buValue !== '' ? this.generateDatasetSection(datasets, usersLoaded, datasetsLoaded) : null}
          {dialogOpen ? this.generateDialog() : null}
        </Grid>
      );
    } 
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className="admin-header">User Group Admin</Typography>
          <div className="cp-div">
            <CircularProgress className="circular-progress" />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  dataset: state.dataset,
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUserBus: actionGetUserBus
  }, dispatch);
}

userGroup.propTypes = {
  auth: PropTypes.object.isRequired,
  getUserBus: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(userGroup));
