import * as actionTypes from '../actions/actionTypes';

const initialState = {
  didLoad: false,
  isLoading: false,
  datasetObjs: []
};

export default function datasetReducer(state = initialState, action) {
  const datasetObjs = action.datasets;
  switch (action.type) {
    case actionTypes.GET_DATASETS_START:
      return { ...state, isLoading: true };
    case actionTypes.GET_DATASETS_SUCCESS:     
      // Sort the datasets in alphabetical order
      datasetObjs.sort((a, b) => a.datasetName.localeCompare(b.datasetName));
      for (let i = 0; i < datasetObjs.length; i += 1) {
        const datasetObj = datasetObjs[i];
        // Sort the tables in alphabetical order
        datasetObj.exportTables.sort((a, b) => a.tableName.localeCompare(b.tableName));
        datasetObj.uploadTables.sort((a, b) => a.tableName.localeCompare(b.tableName));
      }

      return {
        ...state,
        didLoad: true,
        isLoading: false,
        datasetObjs 
      };

    default:
      return state;
  }
}
