import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from './rootReducer';

export const history = createBrowserHistory();

const middleware = [
  thunk,
  routerMiddleware(history)
];

const composedEnhancers = compose(applyMiddleware(...middleware));

export const store = createStore(
  createRootReducer(history),
  {},
  composedEnhancers
);
