import React from 'react';
import { 
  AppBar, Toolbar, Hidden, Popper, Tab, Tabs, Tooltip, Paper, MenuList, MenuItem 
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';
import Logo from '../assets/NBCU Purple Circle.png';
import './header.scss';
import Utils from '../services/utils';

class Header extends React.Component {
  constructor(props, context) {
    super(props);
    const { location } = this.props;

    let value;
    if (['/userAdmin', '/platformAdmin'].includes(location.pathname)) {
      value = location.pathname;
    }
    
    this.state = {
      value,
      open: false,
      anchorEl: null
    };
  }

  handleMenuOpen = (event) => {
    const { currentTarget } = event;
    this.setState({
      open: true,
      anchorEl: currentTarget
    });
  };

  handleMenuClose = (path) => () => {
    let { value } = this.state;
    if (Utils.isNotNullorEmpty(path)) {
      value = path;
    }

    this.setState({
      open: false,
      anchorEl: null,
      value
    });
  };
  
  render() {
    const { location, auth } = this.props;
    let username = <div className="user-name-section" />;
    let navigationLinksSection = <div className="navigation-links-section" />;
    let popper = '';

    const { pathname } = location;
    const { userInfo, isAuthenticated } = auth;
    const { anchorEl, open, value } = this.state;

    if (isAuthenticated) {
      const { isAdminOf, FirstName, LastName } = userInfo;
      const userName = `${FirstName} ${LastName}`;
      const { platformAdmin } = userInfo;

      username = (
        <div>
          <div className="user-name-section">
            <Tooltip disableFocusListener disableTouchListener title={userName}>
              <AccountCircle className="user-name-section" />
            </Tooltip>
          </div>
        </div>
      );

      let adminPages; 
      let adminTab;

      const role = Utils.getRole(platformAdmin, isAdminOf);
      if (role === 'platformAdmin') {
        adminPages = [
          { path: '/userAdmin', label: 'User Group' },
          { path: '/platformAdmin', label: 'Platform' },
        ];
      } else if (role === 'userAdmin') {
        adminPages = [
          { path: '/userAdmin', label: 'User Group' }
        ];
      }

      if (adminPages) {
        adminTab = (
          <Tab 
            value={value} 
            label={<span className="tab">Admin</span>} 
            disableTouchRipple 
            onMouseEnter={this.handleMenuOpen}
          />
        );

        popper = (
          <Popper open={open} anchorEl={anchorEl} onMouseLeave={this.handleMenuClose()} id="menu-list-grow">
            <Paper>
              <MenuList className="menu">
                {adminPages.map((item) => (
                  <MenuItem 
                    component={Link}
                    key={item.path} 
                    onClick={this.handleMenuClose(item.path)}
                    to={item.path}
                    className="menu-item"
                    onMouseEnter={(e) => e.target.style.backgroundColor = '#495054'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = 'black'}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Popper>
        );
      }  
      
      navigationLinksSection = (
        <Tabs
          value={pathname}
          classes={{
            root: 'tab',
            flexContainer: 'tab',
            indicator: 'tab-indicator'
          }}
          style={{ flex: 1 }}
        >
          <Tab 
            component={Link} 
            value="/browse" 
            label={<span className="tab">Browse</span>} 
            to="/browse" 
            disableTouchRipple 
          />
          <Tab component={Link} value="/export" label={<span className="tab">Export</span>} to="/export" disableTouchRipple />
          <Tab component={Link} value="/upload" label={<span className="tab">Upload</span>} to="/upload" disableTouchRipple />
          { adminTab }
        </Tabs>
      );
    }
    return (
      <div>
        <AppBar>
          <Toolbar className="section-header">
            <div className="logo">
              <Link className="logo" to="/browse">
                <img src={Logo} alt="NBCU" width="50" />
              </Link>
            </div>
            { navigationLinksSection }
            { popper }
            <Hidden mdDown>
              { username }
            </Hidden>
          </Toolbar>
        </AppBar>
      </div>
    );
  }    
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
